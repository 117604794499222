import React, { useState } from 'react'
import { Reveal, Animation } from 'react-genie'
import axios from 'axios'

import classes from './Landing.module.css'
import emailSvg from '../../assets/svg/email.svg'
import logoSrc from '../../assets/img/appolo.png'

const Landing = () => {
	const [email, setEmail] = useState('')
	const [submitResult, setSubmitResult] = useState('not sent')
	const [selectedOption, setSelectedOption] = useState('user')

	const isEmailValid = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		return emailRegex.test(email)
	}

	const submitForm = async () => {
		if (!isEmailValid(email)) {
			setSubmitResult('error')
			return
		}

		try {
			const response = await axios.post(
				`https://dev.appolo-colombia.com/api/v1/landing/submit-email`,
				{
					email,
					type: selectedOption
				}
			)

			setSubmitResult('success')

			console.info(response)
		} catch (error) {
			setSubmitResult('error')
		}
	}

	const resetForm = () => {
		setEmail('')
		setSelectedOption('user')
		setSubmitResult('not sent')
	}

	let formContent = (
		<div className={classes.FormGroup}>
			<div className='input-group'>
				<div className='input-group-prepend'>
					<img
						className={['input-group-text', classes.InputGroupText].join(' ')}
						src={emailSvg}
						alt='email icon'
					/>
				</div>
				<input
					type='email'
					placeholder='Ingresa tu correo '
					onChange={(event) => setEmail(event.target.value)}
					className={['form-control', classes.FormControl].join(' ')}
				/>
				<div className='input-group-append'>
					<select
						id='select-form'
						value={selectedOption}
						onChange={(e) => setSelectedOption(e.target.value)}
						className={['form-control', classes.FormControl].join(' ')}
					>
						<option value='day-care'>Guardería</option>
						<option value='user'>Usuario</option>
					</select>
				</div>
			</div>

			<Reveal animation={Animation.BounceInLeft}>
				<button onClick={submitForm}>Regístrate en la versión beta</button>
			</Reveal>
		</div>
	)

	if (submitResult === 'success') {
		formContent = (
			<div>
				<h3>¡Gracias! Te escribiremos pronto.</h3>
			</div>
		)
	}

	if (submitResult === 'error') {
		formContent = (
			<div>
				<h3>Ocurrió un error. Inténtalo de nuevo</h3>
				<button onClick={resetForm}>Reintentar</button>
			</div>
		)
	}

	return (
		<>
			<div className={classes.Announcement}>
				Registra tu correo para participar en nuestra versión beta. Te contactaremos en la mayor
				brevedad posible.
			</div>
			<div className={classes.Landing}>
				<div className={classes.Logo}>
					<img className={classes.LogoImage} src={logoSrc} alt='Appolo Logo' />
				</div>
				<div className={classes.Lead}>
					<h1>
						Encuentra la guardería <span>ideal</span> para tu mascota<span>.</span>
					</h1>
					<h2>
						<Reveal>
							No importa si <em>sólo</em> quieres que pase el día en una guardería nueva, o si
							quieres pagar la mensualidad de tu guardería habitual, ¡<strong>Appolo App</strong> te
							ayuda a encontrarla!
						</Reveal>
					</h2>
					{formContent}
				</div>
			</div>
			<div className={[classes.Announcement, classes.InvertedGradient].join(' ')}>
				Todo para tu mascota... Podrás reservar Hoteles, Guarderías, Baños y ¡mucho más!
			</div>
		</>
	)
}

export default Landing
