import React from 'react'
import { AnimatedTitle } from 'react-genie'
import instagram from '../../assets/svg/instagram.svg'

import classes from './Contacto.module.css'

const Contacto = () => {
	return (
		<div className={classes.Contacto}>
			<h1>
				<AnimatedTitle>Un mejor día para tu mascota.</AnimatedTitle>
			</h1>
			<div className='mb-5'>
				<a href='https://www.instagram.com/appolo.app/'>
					<img src={instagram} alt='' />
				</a>

				<h3>AppoloApp {new Date().getFullYear()} © Todos los derechos reservados.</h3>
			</div>
		</div>
	)
}

export default Contacto
