import React from 'react'
import { Reveal } from 'react-genie'

import classes from './Servicios.module.css'

const Servicios = () => {
	return (
		<div className={classes.Servicios}>
			<Reveal>
				<div className={classes.Servicio}>
					<h1>
						0<span>/ </span>Empieza desde cero<span>.</span>
					</h1>

					<h2>
						Sabemos que tu peludito lo es todo y quieres darle lo mejor siempre. Es por esto que en{' '}
						<strong>Appolo App </strong> te ayudamos a crear/encontrar guarderías 100% certificadas
						y regularmente auditadas.
					</h2>

					{/* <div className={classes.Icono}>
						<img src={iconoUno} alt='Empieza desde cero con la guardería de tu peludito' />
					</div> */}
				</div>
			</Reveal>

			<Reveal>
				<div className={classes.Servicio}>
					<h1>
						1<span>/</span> Encuentra la guardería ideal<span>.</span>
					</h1>

					<h2>
						Busca la guardería ideal para ti y <strong>tu peludito, </strong> dentro de montones de
						opciones. Seguro encontrarás la que mejor se adapta a <strong>ambos.</strong>
					</h2>
					{/* <div className={classes.Icono}>
						<img src={iconoDos} alt='' />
					</div> */}
				</div>
			</Reveal>

			<Reveal>
				<div className={classes.Servicio}>
					<h1>
						2<span>/ </span>Regálale un gran día a tu peludito<span>.</span>
					</h1>
					<h2>
						La guardería puede reservarse por el día siguiente o cualquier rango de tiempo para{' '}
						<strong>tu peludito. </strong>¡Te aseguramos que vivirá una gran experiencia!
					</h2>
					{/* <div className={classes.Icono}>
						<img src={iconoTres} alt='' />
					</div> */}
				</div>
			</Reveal>
		</div>
	)
}

export default Servicios
