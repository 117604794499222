import React from 'react'
import { RevealGlobalStyles } from 'react-genie'
import './App.css'
import Main from './components/Main/Main'

function App() {
	return (
		<div className='App'>
			<RevealGlobalStyles />

			<main className='custom-background'>
				<Main />
			</main>
		</div>
	)
}

export default App
